import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/style.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();


  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://register.tjdstore.in/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }), // Send JSON data
      });
  
      const result = await response.json();
  
      if (result.success) {
        // Save login session to localStorage
        localStorage.setItem('isLoggedIn', 'true');
        // Redirect to the product page
        navigate('/Dashboard');
        navigate('/ProductForm');        
        navigate('/AddProduct');        
        navigate('/CustomerTable');        
        navigate('/TicketForm');                
        navigate('/TicketTable');                
      } else {
        // Show the invalid login message
        setErrorMessage(result.message || 'Invalid username or password');
      }
    } catch (error) {
      console.error('Error during login:', error); // Log error to console
      setErrorMessage('Something went wrong. Please try again.');
    }
  };
  
  return (
    <div>
        <div className="container topic">
            <div className='row justify-content-center'>
                <div className="col-md-6">
                    <div className="card mt-5">
                        <div className="card-body">
                        <h2 class="card-title text-center">Admin Login</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleLogin}>
      <div class="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username" className="form-control"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div class="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password" className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">Login</button>
      </form>
      </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  );
}

export default Login;
