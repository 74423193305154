// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Home from './Pages/Home';

import TicketForm from './Components/TicketForm';
import PrivateRoute from './Components/PrivateRoute'; // Protect routes
import TicketTable from './Components/TicketTable';
import Login from './Components/Login';
function App() {
  return (
    <Router>
      <div>

  

    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <a class="navbar-brand" href="https://register.tjdstore.in"><img className="logoimg" alt="logo" src="https://www.jddigital.in/wp-content/uploads/2024/02/TJD-Digital-Logo-copy.png" width={150}  /></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon" onclick="toggleSidebar()"></span>
    </button>
</nav>    

    <div class="sidebar" id="sidebar">
    <nav class="nav flex-column">
    <ul className="navbar-nav ml-auto">
               <li className="nav-item">
                <Link className="nav-link" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/TicketForm">Create Ticket</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/TicketTable">All Tickets</Link>
              </li>
            </ul>
    </nav>
</div>

        <Routes>
        <Route path="/login" element={<Login />} />
          <Route path="/TicketForm" element={<PrivateRoute><TicketForm /></PrivateRoute>} />
          <Route path="/TicketTable" element={<PrivateRoute><TicketTable /></PrivateRoute>} />                 
        </Routes>

        
      </div>
    </Router>

  );
}

export default App;
