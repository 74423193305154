import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs'; // Import qs for URL encoding
import '../Style.css';
import LogoutButton from './LogoutButton';

const TicketForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        payment_method: 'Cash',
        ticket_cat: 'General',
        amount: 1000,
        reference: '', // Added reference field
        order_id: 1000, // Initialize order_id with default value
    });
    const [smsResponse, setSmsResponse] = useState(''); // To store SMS response


    const ticketPrices = {
        General: 1000,
        Premium: 3250,
        Gold: 8500,
        Supreme: 11000,
        Deluxe: 15000,
        Deluxe_Day1: 5000,        
        Deluxe_Day2: 5000,                
        Super_Deluxe: 21000,
        Super_Deluxe_Day1: 7000,        
        Super_Deluxe_Day2: 7000,                
        Diamond: 30000,
        Diamond_Day1: 10000,
        Diamond_Day2: 10000,        
        Stall: 0.0,
        Media: 0.0,
        VIP: 0.0,
        Delegate: 0.0
    };

    // Fetch last order_id from server
    useEffect(() => {
        axios.get('https://register.tjdstore.in/api/get_last_order_id.php')
            .then(response => {
                const lastOrderId = response.data.order_id;
                const newOrderId = parseInt(lastOrderId, 10) + 1; // Convert to number and add 1
                setFormData(prevState => ({
                    ...prevState,
                    order_id: newOrderId // Set the new order_id
                }));
            })
            .catch(error => {
                console.error('Error fetching last order ID:', error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            amount: name === 'ticket_cat' ? ticketPrices[value] : formData.amount
        });
    };

    const sendSms = async (mobile) => {
        try {
            const res = await fetch('https://register.tjdstore.in/api/ticket_sms.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    order_id: formData.order_id,
                    phone_number: mobile,
                }),
            });

            const data = await res.text();
            setSmsResponse(data);
        } catch (error) {
            console.error('Error sending SMS:', error);
            setSmsResponse('Error sending SMS');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post('https://register.tjdstore.in/api/submit_ticket.php', qs.stringify(formData), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
       

        .then(response => {
            // Send the email using `send_mail.php`
            axios.post('https://register.tjdstore.in/api/ticket_send_mail.php', qs.stringify({
                name: formData.name,
                email: formData.email,
                mobile: formData.mobile,
                payment_method: formData.payment_method,
                ticket_cat: formData.ticket_cat,
                amount: formData.amount,
                reference: formData.reference,
                order_id: formData.order_id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(mailResponse => {
                console.log('Mail sent successfully:', mailResponse.data);
            })
            .catch(mailError => {
                console.error('Error sending email:', mailError.response ? mailError.response.data : mailError.message);
            });

                                // Send SMS
                                sendSms(formData.mobile);

            alert('Form submitted successfully!');
            setFormData({
                order_id: '',
                name: '',
                email: '',
                mobile: '',
                payment_method: 'Cash',
                ticket_cat: 'General',
                amount: 1000,
                reference: '' // Reset reference field
            });
        })
        .catch(error => {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert('An error occurred while submitting the form.');
        });
    };

    return (
        <div className="container topic">
            <div className='row justify-content-center'>
                <div className="col-md-6">
                    <div className="card mt-5">
                        <div className="card-body">
                        <LogoutButton/>

                            <h2 className="center">TICKET BOOKING</h2>
                            <form onSubmit={handleSubmit}>

                            <div className="mb-3">
                                    <label>Order ID:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="order_id"
                                        value={formData.order_id}
                                        readOnly
                                    />
                                </div>  

                                <div className="mb-3">
                                    <label>Name:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Email:</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Mobile:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="mobile"
                                        value={formData.mobile}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label>Ticket Category:</label>
                                    <select
                                        name="ticket_cat"
                                        className="form-select"
                                        value={formData.ticket_cat}
                                        onChange={handleChange}>
                                        <option value="General">General</option>
                                        <option value="Premium">Premium</option>
                                        <option value="Gold">Gold</option>
                                        <option value="Supreme">Supreme</option>
                                        <option value="Deluxe">Deluxe</option>
                                        <option value="Deluxe_Day1">Deluxe Day1</option>
                                        <option value="Deluxe_Day2">Deluxe Day2</option>                                                                                                                        
                                        <option value="Super_Deluxe">Super Deluxe</option>
                                        <option value="Super_Deluxe_Day1">Super Deluxe Day1</option>                                        
                                        <option value="Super_Deluxe_Day2">Super Deluxe Day2</option>                                                                                
                                        <option value="Diamond">Diamond</option>
                                        <option value="Diamond_Day1">Diamond Day1</option>
                                        <option value="Diamond_Day2">Diamond Day2</option>                                        
                                        <option value="Stall">Stall</option>
                                        <option value="VIP">VIP</option>
                                        <option value="Media">Media</option>
                                        <option value="Delegate">Delegate</option>
                                    </select>
                                </div>

                                {/* Reference field for VIP category */}
                                {(formData.ticket_cat === 'VIP' || formData.ticket_cat === 'Delegate') && (
    <div className="mb-3">
        <label>Reference:</label>
        <input
            type="text"
            className="form-control"
            name="reference"
            value={formData.reference}
            onChange={handleChange}
            required
        />
    </div>
)}                               

                                <div className="mb-3">
                                    <label>Amount:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="amount"
                                        value={formData.amount}
                                        readOnly
                                    />
                                </div>

                                <div className="mb-3">
                                    <label>Payment Method:</label>
                                    <select className="form-select" name="payment_method" value={formData.payment_method} onChange={handleChange}>
                                        <option value="Cash">Cash</option>
                                        <option value="Online">Online</option>
                                        <option value="None">None</option>
                                    </select>
                                </div>                            

                                <div className="mb-3">
                                    <button className="btn btn-info" type="submit"><strong>SUBMIT</strong></button>
                                </div>
                            </form>
                        </div>
                        {smsResponse && (
                                <div className="mt-3">
                                    <p>{smsResponse}</p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketForm;
